import React from 'react'
import { ApolloClient, ApolloProvider, HttpLink, InMemoryCache } from '@apollo/client'
import fetch from 'cross-fetch'
import { setContext } from '@apollo/client/link/context'
import { useAuth0 } from '@auth0/auth0-react'

export const ApolloClientProvider = ({ children }) => {
  const { getAccessTokenSilently } = useAuth0()

  const authLink = setContext(async (_, { headers }) => {
    const token = await getAccessTokenSilently()
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
      },
    }
  })

  const httpLink = new HttpLink({
    uri: process.env.GATSBY_ARTLAB_API_ENDPOINT,
    fetch,
  })

  const cache = new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          allTodos: {
            keyArgs: false,
            merge(existing = {}, incoming) {
              // console.log('existing:', existing)
              // console.log('incoming', incoming)
              const mergedData = (existing.data ?? []).concat(incoming.data)
              const uniqueData = [...new Map(mergedData.map(item => [item.__ref, item])).values()]
              return Object.assign({}, incoming, { data: uniqueData })
            },
          },
        },
      },
    },
  })

  const apolloClient = new ApolloClient({
    link: authLink.concat(httpLink),
    cache,
  })

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
}
