// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-node-view-tsx": () => import("./../../../src/components/node-view.tsx" /* webpackChunkName: "component---src-components-node-view-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-branch-tsx": () => import("./../../../src/pages/branch.tsx" /* webpackChunkName: "component---src-pages-branch-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-node-tsx": () => import("./../../../src/pages/node.tsx" /* webpackChunkName: "component---src-pages-node-tsx" */)
}

