import React from 'react'
import { Auth0Provider } from '@auth0/auth0-react'
import { navigate } from 'gatsby'

import { ApolloClientProvider } from './src/utils/apollo-client-provider'

const onRedirectCallback = (appState) => navigate(appState?.returnTo || '/member')

export const wrapRootElement = ({ element }) => {
  return (
    <Auth0Provider
      domain={process.env.GATSBY_AUTH0_DOMAIN}
      clientId={process.env.GATSBY_AUTH0_CLIENT_ID}
      redirectUri={typeof window !== 'undefined' ? window.location.origin : ''}
      onRedirectCallback={onRedirectCallback}
      audience="https://api.artlab.eco/v1/hub"
      scope="read:members"
    >
      <ApolloClientProvider>
      {element}

      </ApolloClientProvider>
    </Auth0Provider>
  )
}
